nav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}
nav h3 {
  margin: 1rem;
  cursor: pointer;
  color: #718096;
}
nav h3:hover {
  color: white;
}
.auth-container-inner {
    background: #1a202c;
    border-radius: 25px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
    color: white;
}
.auth-container-outer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 115vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(128, 128, 128, 0.5);
}

form label {
    font-size: 1rem;
    font-weight: bold;
    display: block;
    text-align: left;
    margin: 1rem 0 0.2rem;
  }
  
input,
select {
    width: 100%;
    max-width: 500px;
    padding: 0.65rem 0.5rem;
    font-size: 1rem;
    color: white;
    border: 2px solid #4a5568;
    background-color: #2d3748;
    border-radius: 10px;
    outline: none;
}
